import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/SEO";

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy" />
    <div className="legalContent">
      <h1 id="top">PRIVACY POLICY</h1>
      <p>
        <strong>Last Update: May 22, 2020</strong>
      </p>
      <p>
        The mobile-enabled website located at{' '}
        <a href="https://www.supplycaravan.com">www.supplycaravan.com</a> (the “
        <strong>Site</strong>”) is brought to you by Phoenician Trading Company,
        LLC d/b/a Supply Caravan (“<strong>Supply Caravan</strong>,” “
        <strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”).
        This privacy policy (the “<strong>Privacy Policy</strong>”) provides
        information about our policies and procedures regarding the collection,
        use and disclosure of information received through the Site (the
        “**Privacy Policy”).
      </p>
      <p>
        This Privacy Policy is incorporated into, and is part of, our{' '}
        <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>{' '}
        which govern your access to and use of the Site. If you have comments,
        suggestions, questions or concerns about our Privacy Policy, please
        contact us at{' '}
        <a href="mailto:legal@supplycaravan.com">legal@supplycaravan.com</a>.
      </p>
      <p>
        There are a few definitions from our{' '}
        <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>{' '}
        that we will use frequently in this Privacy Policy and so we have defined
        them again for you below for easy reference. Capitalized terms used but
        not defined in the Privacy Policy have their respective meanings in the{' '}
        <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>.
      </p>
      <ul>
        <li>
          “<strong>Buyer</strong>” means anyone who visits the Site to purchase
          Goods.
        </li>
        <li>
          “<strong>Carrier</strong>” means a commercial provider of transportation
          who visits the Site to bid on the transportation of Goods exchanged by
          Buyers and Sellers.
        </li>
        <li>
          “<strong>Goods</strong>” refers to wholesale items being purchased, sold
          or lined up for transport through the Site.
        </li>
        <li>
          “<strong>Listing</strong>” refers to a request or offer for Goods, or
          transportation for Goods, which is posted by Users in the Marketplace.
        </li>
        <li>
          “<strong>Marketplace</strong>” means the online marketplace available at
          the Site for the sale of Goods.
        </li>
        <li>
          “<strong>Seller</strong>” means an individual or company who visits our
          Site in order to sell or act as a distributor of Goods.
        </li>
        <li>
          “<strong>Users</strong>,” “<strong>you</strong>” or “
          <strong>your</strong>” refer to the people and/or entities visiting the
          Site or registered to use the Services as Buyers, Sellers and Carriers.
        </li>
        <li>
          “<strong>Transaction</strong>” refers to a commercial arrangement
          between Users facilitated through the Site.
        </li>
      </ul>
      <p>
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING THE SITE OR
        SUBMITTING INFORMATION THROUGH THE SITE, YOU AGREE TO THE COLLECTION,
        TRANSFER, STORAGE, DISCLOSURE AND USE OF YOUR INFORMATION AS DESCRIBED IN
        THIS PRIVACY POLICY. IF YOU DO NOT ACCEPT THE TERMS OF THIS PRIVACY
        POLICY, PLEASE DO NOT ACCESS OR USE THE SITE.
      </p>
      <h2 id="i">I. Amendments to this Privacy Policy</h2>
      <p>
        This Privacy Policy may be amended from time to time, with or without
        notice to Users. Supply Caravan recommends that you revisit this page
        frequently, so you are aware of any changes. As appropriate, Supply
        Caravan may provide you additional notice of any material amendment. Your
        continued use of the Site and Services will be deemed your agreement that
        your information may be used in accordance with the new policy. If you do
        not agree with the changes, then you should stop using the Site and notify
        us immediately that you do not want your information used in accordance
        with the changes.
      </p>
      <h2 id="ii">II. Your Information</h2>
      <p>
        Supply Caravan respects the privacy and other rights of its Users. This
        Privacy Policy outlines the ways in which we may collect and use
        information about Users.
      </p>
      <h3 id="a-collection-of-personal-information-and-other-user-information">
        a. Collection of Personal Information and other User Information
      </h3>
      <p>
        “<strong>Personal Information</strong>” means all personally-identifiable
        information (e.g., your name, physical address, e-mail address, telephone
        numbers) that you submit to Supply Caravan and/or the Site, including but
        not limited to with your Account Credentials (as defined in the{' '}
        <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>).
        Supply Caravan does not collect Personal Information <u>unless</u> you
        voluntarily submit it in connection with your use of the Services, send
        inquiries to Supply Caravan, or participate in certain activities made
        available through the Site or Services.
      </p>
      <p>
        “<strong>User Information</strong>” is further defined in our{' '}
        <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a> and
        may include without limitation:
      </p>
      <ul>
        <li>Personal Information about a User;</li>
        <li>
          Information about a User which is included in a Listing uploaded to the
          Marketplace;
        </li>
        <li>
          Information provided by a User in response to a survey or promotional
          registration, when filling out web-based forms or subscribing to our
          newsletter, and in connection with other activities, features and
          functionality through the Site;
        </li>
        <li>
          A User’s Account Credentials and Transaction history, including a record
          of Listings, Offers and Counteroffers, searches and other activities;
        </li>
        <li>
          Data from the Site’s messaging functionality and e-mail, chat and other
          customer support communications;
        </li>
        <li>
          Anonymous Information (as explained in more detail below) that we
          receive about Users;
        </li>
        <li>
          Information we receive from third parties about Users, including, but
          not limited to, advertising partners, customers and vendors; and
        </li>
        <li>
          Any other information a User chooses to share with Supply Caravan when
          communicating with us.
        </li>
      </ul>
      <p>
        We may collect User Information (including Personal Information) when you
        choose to use certain features of the Site and Services, including when
        you: (i) create a Supply Caravan Account; (ii) use the Services to connect
        with other Users, (iii) communicate with Supply Caravan, (iv) upload
        information, media, contents, comments, videos or photographs in a Listing
        or otherwise, or (v) consent to receive commercial or promotional email
        and/or direct mail from Supply Caravan. We also collect Personal
        Information for maintaining records and contact details for our business
        purposes.
      </p>
      <p>
        Users should be aware that any content that is included in a Listing
        published or displayed in the Marketplace:
      </p>
      <ul>
        <li>
          May be reviewed by Supply Caravan before it is approved for publication
          and distribution through the Site;
        </li>
        <li>Will be accessible to all Users accessing the Site;</li>
        <li>
          Can be associated with Users whether or not they are actively using the
          Services; and
        </li>
        <li>
          May be visible and accessible through general search engines like
          Google.
        </li>
      </ul>
      <h3 id="b-online-registration-for-supply-caravan-account">
        b. Online Registration for Supply Caravan Account
      </h3>
      <p>
        Users must register for an online account (a “
        <strong>Supply Caravan Account</strong>”) when using our Site to buy, sell
        or bid on the transport of Goods. Once you have completed the registration
        process with Supply Caravan, you will be able to access the Site using
        your username and a unique password. Your password is exclusively for your
        use and should not be shared except as needed to facilitate use of the
        Site by you and those individuals authorized to use the Site on your
        behalf. It is the responsibility of each User to safeguard her, his or its
        password. If you believe your password has been lost, stolen or otherwise
        compromised, or that the security of your Supply Caravan Account has been
        compromised in any way, please contact Supply Caravan immediately.
      </p>
      <p>
        We will ask all Users to provide the following information (your “
        <strong>Account Credentials</strong>”) in connection with creating a
        Supply Caravan Account:
      </p>
      <ul>
        <li>Name;</li>
        <li>E-mail address;</li>
        <li>Mailing address;</li>
        <li>Phone number; and</li>
        <li>Full address for shipping purposes.</li>
        <li>
          You will also be asked to create a username and password and provide
          certain optional information about you and your business.
        </li>
      </ul>
      <p>
        When you set up a Dwolla Account (as described in{' '}
        <a href="#vii">Section VII</a> below), please note that Dwolla will
        require additional information from you in order to verify your bank
        account to facilitate transactions through the Site. Depending on whether
        or not you intend to receive or send funds in connection with a
        Transaction, you may be required to provide some or all of the following
        information directly to Dwolla (as more fully explained in{' '}
        <a href="#vii">Section VII</a>):
      </p>
      <p>For receive-only customers and unverified customers:</p>
      <ul>
        <li>Full name;</li>
        <li>Physical address; and</li>
        <li>Email address.</li>
      </ul>
      <p>For verified personal customers:</p>
      <ul>
        <li>Full name;</li>
        <li>Date of birth;</li>
        <li>Physical address;</li>
        <li>Email address; and</li>
        <li>Social security number (last four digits).</li>
      </ul>
      <p>For verified business customers (sole proprietorships):</p>
      <ul>
        <li>Business name;</li>
        <li>Business address;</li>
        <li>Business classification;</li>
        <li>Owner name;</li>
        <li>Owner’s social security number (last four digits); and</li>
        <li>Email address.</li>
      </ul>
      <p>For verified business customers (LLC, Corp, Partnership):</p>
      <ul>
        <li>Business name;</li>
        <li>Business address;</li>
        <li>Business classification;</li>
        <li>Legal entity type</li>
        <li>Employer Identification Number</li>
        <li>Owner name;</li>
        <li>Email address;</li>
        <li>Controller name;</li>
        <li>Controller title;</li>
        <li>Controller address;</li>
        <li>Controller date of birth; and</li>
        <li>Controller’s social security number (last four digits).</li>
      </ul>
      <p>
        Users may visit the Site without registering for a Supply Caravan Account
        but Supply Caravan may collect Personal Information provided by the User
        and Anonymous Information in its discretion. Each User or authorized
        individual of a User who does register for a Supply Caravan Account
        warrants that she or he is over 18 years of age and will provide us with
        complete, accurate, and current Personal Information. You must update your
        Personal Information if it changes and you consent to be contacted by
        Supply Caravan from time to time to verify your Personal Information.
      </p>
      <p>
        All fees and costs related to a User’s personal hardware, Internet access
        and software, including all upgrades and updates, shall be a User’s sole
        financial responsibility.
      </p>
      <h3 id="c-information-gathered-automatically">
        c. Information Gathered Automatically
      </h3>
      <p>
        We may collect additional information about your interactions with the
        Site and Services (including metadata) that does not identify you as an
        individual (“<strong>Anonymous Information</strong>”) by using cookies,
        web beacons or other automated technologies (as explained in more detail
        in Section V below). For example, we may receive certain standard
        information that your browser sends to many or all websites you visit,
        including but not limited to:
      </p>
      <ul>
        <li>Time and date of your visit and the pages you visit;</li>
        <li>
          Your internet service provider, IP address and technical information
          about the IP address;
        </li>
        <li>Browser and operating system type;</li>
        <li>
          Information about the network and device you are using (such as whether
          it is a mobile device or a desktop computer); and
        </li>
        <li>Your geographic location.</li>
      </ul>
      <p>
        Supply Caravan may use Anonymous Information for any purpose in its
        discretion. For clarity, Anonymous Information means information that is
        not associated with or linked to your Personal Information and does not
        permit the identification of individual persons.
      </p>
      <h3 id="d-user-generated-content">d. User Generated Content</h3>
      <p>
        The Site permits Users to submit certain content to the Site (“
        <strong>User Generated Content</strong>”), including Listings in the
        Marketplace and reviews and ratings related to Goods and/or interactions
        with other Users through the Site. Supply Caravan assumes no
        responsibility for securing User Generated Content you submit via the
        Services. Supply Caravan has no obligation to use, solicit or enable
        others to use any User Generated Content you submit to us. WE ARE NOT
        RESPONSIBLE FOR THE PERSONAL INFORMATION AND OTHER NON-IDENTIFYING
        INFORMATION YOU CHOOSE TO SUBMIT IN ANY PUBLICLY ACCESSIBLE AREA OF THE
        SITE.
      </p>
      <h3 id="e-messaging">e. Messaging</h3>
      <p>
        Users may communicate with other users using a messaging services
        facilitated through the Site. When you send a message (which may include
        text and photos) to other Users of the Services, we may retain the
        messages (“<strong>Messages</strong>”) for as long as needed to provide
        the Services and to comply with our legal obligations, to resolve disputes
        and to enforce our agreements.
      </p>
      <h3 id="f-communications-with-supply-caravan">
        f. Communications with Supply Caravan
      </h3>
      <p>
        We collect Personal Information from you when you communicate with us, for
        example when you request information about the Site or Services, contact
        our support team, apply for a job, or register for a newsletter, survey,
        promotion or other activity through the Site.
      </p>
      <h3 id="g-retention">g. Retention</h3>
      <p>
        Supply Caravan may retain any Personal Information that you submit in
        connection with your Supply Caravan Account <u>unless</u> Supply Caravan
        deems such Personal Information to violate this Privacy Policy or until
        such time as you decide to remove or request deletion of such Personal
        Information. You understand that deleted Personal Information may persist
        for a reasonable period of time on Supply Caravan’s backup servers. By
        providing your User Information to us through the Site, you accept that we
        may retain this information as explained herein.
      </p>
      <h2 id="iii">III. How We Use Your Information</h2>
      <p>
        <em>General Use</em>. We may use User Information (including Personal
        Information) for the following purposes:
      </p>
      <ul>
        <li>
          To operate the Site and Marketplace and to deliver, monitor and improve
          the Services;
        </li>
        <li>
          In connection with our relationships with Business Partners (as
          described in more detail below);
        </li>
        <li>
          To allow us to perform certain verification steps on Users (as described
          in our{' '}
          <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>);
        </li>
        <li>
          To maintain the safety and security of Supply Caravan, the Site and
          Services, and data submitted to Supply Caravan;
        </li>
        <li>
          To ensure data accuracy and integrity, and to safeguard the rights of
          Users and Supply Caravan;
        </li>
        <li>
          To personalize a User’s experience at the Site and to allow us to
          deliver relevant content and product offerings;
        </li>
        <li>
          To improve internal operations of Supply Caravan, including for
          troubleshooting, research and enhancement of Services;
        </li>
        <li>
          To respond to your requests and inquiries and to provide you with
          requested customer support;
        </li>
        <li>
          To review Site usage in order to customize, measure and improve the
          Services and the content, layout and functionality of the Site;
        </li>
        <li>
          To communicate with you about any problems with the Site or Services, or
          to inform you about important changes to the Site, Services and/or our
          business;
        </li>
        <li>
          To administer a promotion or survey or to identify and offer you new
          products and services;
        </li>
        <li>
          To tailor the Site to your needs and the needs of other Users; and
        </li>
        <li>
          For other purposes to the extent authorized and/or required by law.
        </li>
      </ul>
      <p>
        <em>Direct Marketing</em>. We may send out marketing messages (such as a
        newsletter or alert) to Users who have provided us with contact
        information to communicate about developments with our business, to offer
        special promotions, or to announce important happenings in our industry.
        We will ask for your consent to receive marketing communications when we
        collect your contact information, and you can always choose to stop
        receiving newsletters or other communications from us by clicking the
        “unsubscribe” link at the bottom of a marketing message. Please understand
        that if you choose not to receive promotional correspondence from us, we
        may still contact you in connection with your relationship, activities,
        transactions and/or communications with us.
      </p>
      <h2 id="iv">IV. How Users’ Information Is Shared</h2>
      <p>
        <em>Third Party Sharing</em>. Supply Caravan may allow third parties to
        access User Information (including Personal Information) ONLY IF:
      </p>
      <ul>
        <li>
          You have voluntarily shared the User Information with Supply Caravan
          and/or in a Listing, as part of a review or rating published on the
          Site, or otherwise made the User Information available on the Site;
        </li>
        <li>
          We have a legitimate business purpose for sharing such User Information
          with service providers, such as Business Partners, under contract with
          Supply Caravan who help with our business operations;
        </li>
        <li>
          With respect to Personal Information, we have removed your name and any
          other individually identifying information; or
        </li>
        <li>We have your express permission to do so.</li>
      </ul>
      <p>
        Supply Caravan cannot control whether or how third parties share your User
        Information that you have shared with them, or your User Information that
        third parties have accessed through means other than the Services.
        However, Supply Caravan’s Payment Processors will not disclose your
        financial information without your consent.
      </p>
      <p>
        <em>Aggregated Research</em>. We may also share aggregated
        non-identifiable information about our Users with third parties in order
        to help market our products and develop new vendor and customer
        relationships.
      </p>
      <p>
        <em>Other Circumstances</em>. As with any business, it is possible that in
        the future we could merge with or be acquired by another company. If this
        occurs, the successor company would have access to the information we
        collect, but would continue to be bound by this Privacy Policy unless and
        until it is amended. For clarity, we may disclose your Personal
        Information if we are involved in a merger, acquisition or sale of any or
        all of our business and/or our assets to such third parties, or if we have
        a good faith belief that disclosure is necessary to:
      </p>
      <ul>
        <li>
          Comply with applicable laws, regulations, legal process (such as a
          subpoena) or enforceable government request;
        </li>
        <li>
          Enforce our{' '}
          <a href="https://www.supplycaravan.com/terms">Terms and Conditions</a>,
          including investigation of potential violations or to detect, prevent or
          otherwise address fraud, security or technical issues with respect to
          the Site;
        </li>
        <li>
          Facilitate fraud prevention, risk assessment, investigation, product
          development and debugging purposes; and
        </li>
        <li>
          Protect against harm to the rights, property or safety of Supply
          Caravan, our Users or the public as required or permitted by law.
        </li>
      </ul>
      <h2 id="v">V. Cookies, Beacons and Other Automated Technologies</h2>
      <p>
        Supply Caravan and its Analytics Partners (as described below) may use
        cookies, beacons and the like through the Site to monitor and enhance
        Users’ experience with the Services and for advertising and research
        purposes; to store your information; and to protect you, Supply Caravan
        and others.
      </p>
      <ul>
        <li>
          <em>Cookies</em>. A “cookie” is a small computer file that is sent to
          your computer when you visit a website to make each visit more
          efficient. Cookies may store User preferences and User Information. You
          may change your browser options to opt out of receiving cookies by
          instructing your browser to stop accepting cookies or to prompt you
          before accepting a cookie from websites you visit. Please note, however,
          that by blocking or deleting cookies used with the Site and Services,
          you may not be able to use all aspects of the Site and Services. You can
          learn more about cookies by visiting{' '}
          <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>,
          which includes useful information about cookies and how to block cookies
          on different types of browsers and mobile devices.
        </li>
        <li>
          <em>Web Beacons</em>. A “web beacon” is a small piece of information or
          data stored on your computer or mobile device that is used to determine
          your preferences and to track your search terms and other behaviors or
          activities as you navigate around the Internet. If you object to this
          practice, you may erase or block certain web beacons. We recommend,
          however, that you allow web beacons to be stored on your computer or
          mobile device as they may be needed to properly use the Services.
        </li>
        <li>
          <em>“Do Not Track” Requests</em>. The Site currently does not respond to
          “do not track” or similar signals from your browser.
        </li>
      </ul>
      <h2 id="vi">VI. Security</h2>
      <p>
        We use commercially reasonable physical, electronic and operational
        procedures designed to safeguard and secure the information we collect on
        our Site. We also limit access to Personal Information we receive to our
        employees, contractors, service providers (such as Business Partners) and
        professional advisors who need access to that information in order to do
        their jobs or provide services to us. However, no data protection
        procedures are entirely secure. While we strive to protect your Personal
        Information, we cannot guarantee that it will be 100% secure. As is the
        case with most websites you visit, the transmission of data to our Site is
        done entirely at your own risk.
      </p>
      <h2 id="vii">VII. Business Partners</h2>
      <p>
        We use and work with certain third party application and technology
        companies (“<strong>Business Partners</strong>”) for hosting, payment
        processing, marketing, technology development, business intelligence,
        analytics and other services for us or for our Users. These Business
        Partners may have access to or process your information for the purpose of
        providing those services. Some functionality in the Site may use
        white-labelling techniques to serve content from Business Partners while
        providing the look and feel of our Site. Please note that, in some
        instances, you are providing your information to these third parties
        acting on behalf of Supply Caravan. We have discussed certain of our
        Business Partners in more detail below.
      </p>
      <h3 id="a-analytics-partners">a. Analytics Partners</h3>
      <p>
        Supply Caravan partners with analytics partners (“
        <strong>Analytics Partners</strong>”) to collect Anonymous Information
        about how our Site is used. Our Analytics Partners may use cookies,
        beacons or other automated technologies to collect information about you.
        Anonymous Information may be used for any purposes in the discretion of
        Supply Caravan, including to develop market data and analysis.
      </p>
      <p>
        In order to make sure you understand how our Analytics Partners handle
        User Information and interact with our Site, we’ve shared a hyperlink
        below to its terms. You should visit this page as Supply Caravan has no
        ability to control or monitor our Analytics Partners’ data collection or
        data use practices.
      </p>
      <ul>
        <li>
          Google Analytics’ terms are available here:{' '}
          <a href="https://www.google.com/analytics/terms/us.html">
            https://www.google.com/analytics/terms/us.html
          </a>
          .
        </li>
        <li>
          Fullstory’s terms are available here:{' '}
          <a href="https://www.fullstory.com/legal/terms-and-conditions/">
            https://www.fullstory.com/legal/terms-and-conditions/
          </a>
        </li>
      </ul>
      <h3 id="b-payment-processors">b. Payment Processors</h3>
      <p>
        Supply Caravan has partnered with third party payment processors (“
        <strong>Payment Processors</strong>”) in order to make it possible for
        Buyers to make payments through the Site. In order to use our ACH payment
        functionality powered by Dwolla, Inc. (“<strong>Dwolla</strong>”), you
        must open a &quot;Dwolla Platform&quot; account provided by Dwolla, Inc.
        (a “<strong>Dwolla Account</strong>”) and you must accept the Dwolla{' '}
        <a href="https://www.dwolla.com/legal/tos/">Terms of Service</a> and{' '}
        <a href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>.
      </p>
      <h2 id="viii">VIII. Links to Third Party Websites</h2>
      <p>
        The Site may contain links to other websites, including those of third
        parties such as Business Partners and Industry Partners. While we seek to
        link only to websites that share our high standards and respect for
        privacy, we cannot be responsible for the privacy practices of other
        websites. By accessing other third party websites or applications through
        our Site, you are consenting to the terms and privacy policies of those
        websites. It is possible that other parties may collect Personal
        Information about your online activities over time and across different
        web sites when you use the Site.
      </p>
      <h2 id="ix">IX. California Privacy Rights</h2>
      <p>
        Under California’s &quot;Shine the Light&quot; law, California residents
        who provide personal information in obtaining products or services for
        personal, family or household use are entitled to request and obtain from
        us once a calendar year information about the customer information we
        shared, if any, with other businesses for their own direct marketing uses.
        If applicable, this information would include the categories of customer
        information and the names and addresses of those businesses with which we
        shared customer information for the immediately prior calendar year (e.g.
        requests made in 2020 will receive information regarding 2019 sharing
        activities).
      </p>
      <p>
        To obtain this information, please send an email message to{' '}
        <a href="mailto:legal@supplycaravan.com">legal@supplycaravan.com</a> with
        &quot;Request for California Privacy Information&quot; in the subject line
        and in the body of your message. After confirming your identity, we will
        provide the requested information to you at your e-mail address in
        response. Please be aware that not all information sharing is covered by
        the &quot;Shine the Light&quot; requirements and only information on
        covered sharing will be included in our response.
      </p>
      <h2 id="x">X. For Canadian Users</h2>
      <p>
        Canadian citizens, except under circumstances defined by law, are entitled
        to access their Personal Information collected by Supply Caravan. If you
        believe that the Personal Information about you that we have collected is
        incomplete or inaccurate, please contact us and we will correct the
        information upon verification of the omission or error and of the identity
        of the person requesting the change.
      </p>
      <p>
        Canadian citizens who have questions or concerns about our privacy
        practices may contact us using the contact information set forth below.
      </p>
      <h2 id="xi">
        XI. Other International Users and the General Data Protection Regulation
      </h2>
      <p>
        The Site and Services are operated in the United States and we host all
        data received through the Site and Services in the United States. By using
        the Site and Services, you are consenting to the transfer of your Personal
        Information to the United States.
      </p>
      <p>
        If you are a User from a member state of the European Union, please be
        advised that Supply Caravan is committed to complying with the principles
        of the General Data Protection Regulation (the “<strong>GDPR</strong>”)
        and has technology and business practices in place so that Users may take
        advantage of certain choices and protections encouraged by the GDPR. For
        example:
      </p>
      <ul>
        <li>
          Users can easily access their Personal Information stored in their
          Supply Caravan Account at any time.
        </li>
        <li>
          Users may delete their profile at any time. If requested by a User,
          Supply Caravan will promptly delete any information we store about the
          User, subject to reasonable retention on backup servers.
        </li>
        <li>
          Users may also request a copy of their Personal Information stored by
          Supply Caravan by contacting us directly. If your information has been
          shared with a third party, as described elsewhere in this Privacy
          Policy, then that third party has received its own copy of your data. If
          you have been contacted by one of these third parties and wish to
          correct or delete your information, please contact them directly.
        </li>
      </ul>
      <p>
        Please also know that you have the right to complain to your local
        information protection authority should you have concerns with respect to
        how we are processing your Personal Information. Your local data
        protection authority will be able to give you more information on how to
        submit a complaint.
      </p>
      <h2 id="xii">XII. Users Only of Legal Age of Majority</h2>
      <p>
        THE SITE AND SERVICES ARE DESIGNED AND INTENDED FOR USE BY THOSE WHO ARE
        AT LEAST EIGHTEEN (18) YEARS OF AGE OR OLDER. We are not liable for any
        damages that may result from a User’s misrepresentation of her or his age.
        No one under age 13 is authorized to submit any information to Supply
        Caravan, directly or through the Site or Services, whether or not it is
        Personal Information. Under no circumstances may anyone under age 13 visit
        or use our Site. Parents or legal guardians of children under 13 cannot
        agree to this Privacy Policy or the Agreement on a minor child’s behalf.
      </p>
      <h2 id="xiii">XIII. Contact Supply Caravan</h2>
      <p>
        If you have questions or complaints regarding this Privacy Policy, the
        Site and Services, or our business practices, please contact us using the
        contact information below.
      </p>
      <p>
        Address: Supply Caravan, 116 Middle Street, Portsmouth, NH 03801
        <br />
        Phone: +1 855-566-1389
        <br />
        Email:{' '}
        <a href="mailto:legal@supplycaravan.com">legal@supplycaravan.com</a>
      </p>
    </div>
  </Layout>
);

export default PrivacyPage;
